import React from 'react';
import Layout from '../../components/Layout';
import TopBar from '../../components/TopBar';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ConnectivityTestForm
  from '../../components/ConnectivityTestForm';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { SIGN_IN } from '../../constants/routes';
import GatsbyLinkedButton from '../../components/Button';

const TestPage = (props) => {
  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <TopBar/>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box p={2}>
            <ConnectivityTestForm {...props} />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default TestPage;
