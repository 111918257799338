import React, { useState } from 'react';
import { useLocation } from '@reach/router';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useClipboard from "react-use-clipboard";

import useForm, { DEFAULT_FIELD } from '../../hooks/useForm';
import ButtonWithLoading from '../../components/Form/inputs/ButtonWithLoading';
import Email, { validator as emailValidator } from '../../components/Form/inputs/Email';
import TextField from '@material-ui/core/TextField';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button';

const translations = {
  ui: {
    buttons: {
      submit: {
        label: 'Submit',
      },
      start: {
        label: 'Start',
      },
    },
  },
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  errorContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
    backgroundColor: '#f88'
  },
  formFooter: {
    margin: theme.spacing(2),
  },
  formButtons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  fields: {
    margin: theme.spacing(1),
    width: 300,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderRadius: 3,
  },
}));


const validators = {
  email: emailValidator,
}

const createTest = async ( {email, clientTimestamp} ) => {
  const data = {
    email,
    clientTimestamp,
  }
  const url = '/network/api/tests';
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer', 
    body: JSON.stringify(data) 
  })
  return response.json();
}

const submitEmail = ({ event, inputs, props, setInputs, setSuccess }) => {
  const { email } = inputs.fields;
  return createTest({ email: email.value, clientTimestamp: new Date() })
  .then(async (data) => {
    props.setSubmit(data.id);
    props.setTestConfig(data.testConfig);
    setInputs(nextInputs => ({
      ...nextInputs,
      fields: {
        ...nextInputs.fields,
        email: DEFAULT_FIELD,
      },
    }));
    return ({
      result: {},
    });
  }).catch((error) => {
    console.log('error', error);
    props.setError(error);
    return ({
      errors: error,
      result: {},
    });
  })

}

const start = (submitted, testConfig) => (event) => {
  const { url, regions, startRegion } = testConfig;
  window.location.href = `${url}?uid=${submitted}&tests=${regions}&region=${startRegion}`;
};

const ConnectivityTestForm = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const msg = (params.get('msg') === 'Completed');
  const [submitted, setSubmit] = useState();
  const [testConfig, setTestConfig] = useState();
  const [error, setError] = useState();

  const classes = useStyles();
  const {
    inputs,
    handleInputChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    loading,
    success,
  } = useForm({...props, setSubmit, setTestConfig, setError}, submitEmail, {
    email: DEFAULT_FIELD,
  }, validators);
  const [setCopied] = useClipboard(error);

  return (
    <Grid container spacing={2} justify="center">
      <Grid item md={6}>
        <Paper>
        {msg &&
          <Box p={3}>
            <Typography gutterBottom variant="h2" component="h1" align="center">Test completed. Thanks <span role="img" aria-label="smiling face emoji">🙂</span></Typography>
          </Box>
        }
        {!msg && !submitted && 
          <React.Fragment>
            <Typography gutterBottom variant="h2" component="h1" align="center">Enter your details</Typography>
            <Typography gutterBottom variant="body2" align="center">version 1.2</Typography>

            <Box p={3}>
              <Typography gutterBottom variant="body1">
              The network test is intended to check connectivity in video calls from different datacenters.
              It will send the results to our database for further analysis.
              </Typography>
            </Box>
            <form className={classes.container}>
              <Email
                handleInputChange={handleInputChange}
                handleBlur={handleBlur}
                handleFocus={handleFocus}
                inputs={inputs}
                className={classes.fields}
              />
              <Box className={classes.formButtons} p={3}>
                <ButtonWithLoading
                  color="primary"
                  onClick={event => handleSubmit(event, inputs)}
                  disabled={loading}
                  loading={loading} success={success}
                  label={translations.ui.buttons.submit.label} />
              </Box>
            </form>
            {error &&
                <Box p={2} className={classes.errorContainer}>
                  <Typography variant="h5" gutterBottom>Problem detected</Typography>
                  <TextField helperText="Pleas send this error data" fullWidth defaultValue={error} rows="10" multiline />
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={setCopied}
                    startIcon={<FileCopyIcon />}
                  >
                    Copy error text to clipboard
                  </Button>
                </Box>              
            }
          </React.Fragment>

        }
        {!msg && submitted && <Box className={classes.container}>
          <Box p={3}>
            <Typography gutterBottom variant="body1">
              Press Start to initiate the test process. It will take around 5-10 mins.
              Wait for success message. Thanks.
            </Typography>
          </Box>
          <Box className={classes.formButtons} p={3}>
            <ButtonWithLoading
              color="primary"
              onClick={event => start(submitted, testConfig)(event)}
              label={translations.ui.buttons.start.label} />
            </Box>
          </Box>
      }

        </Paper>
      </Grid>

    </Grid>
  )
}

export default ConnectivityTestForm;
